import { motion } from 'framer-motion';

function MoveIn({ children, delay }) {
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{ opacity: 1}}
      transition={{
        duration: 0.5,
        delay: delay
      }}
    >
      {children}
    </motion.div>
  );
}

export default MoveIn;

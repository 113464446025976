import CommonInformationContainer from '../CommonInformationContainer';
import ScrollFadeIn from '../../animations/motion/ScrollFadeIn';

function OurServicesContainer() {

    return (
        <CommonInformationContainer>
            <ScrollFadeIn>
                <h1>Our Services</h1>
                <span>
                    Shorewoods operates as a market maker across many DEXes, providing liquidity and improving spreads between the prices. We aggregate real time order book data and trades from multiple DEXes, which allows clients to access deep liquidity and the best available prices through our simple and easy to use interface. Our aggregation service continuously monitors market conditions and dynamically routes orders to get the best prices.
                </span>
            </ScrollFadeIn>
        </CommonInformationContainer>
    );
}

export default OurServicesContainer;
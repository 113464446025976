import './styles/sidebar.css'
import MoveIn from '../animations/motion/MoveIn';
import { RxCross1 } from "react-icons/rx";
import { useState, useEffect } from 'react';
import menuItems from '../topbar/TopbarItems.json'
import { Link } from 'react-router-dom';

function SlideSideBar({ showSidebar, setShowSidebar }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        // Set menu items from the imported JSON data
        setItems(menuItems);
    }, []);
    return (
        showSidebar &&
        <MoveIn delay={0}>
            <div className="slide-sidebar">
                <div className='close-icon' onClick={() => setShowSidebar(false)}>
                    <div className='close-icon-frame'>
                        <RxCross1 />
                    </div>
                </div>
                <div className='sidebar-menu'>
                    <nav>
                        <ul>
                            {items.map((item) => (
                                <li key={item.id}>
                                    <Link to={item.url}>{item.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

            </div>
        </MoveIn>
    );
}

export default SlideSideBar;
import './styles/homecontainermain.css'
import Typewriter from 'typewriter-effect';
import LeftFadeIn from '../animations/motion/LeftFadeIn';

function HomeContainerMain() {
    return (
        <div className='home-container'>
            <div className="home-container-title">
                <LeftFadeIn delay={0.5}>
                    <h1>We Serve As&nbsp;
                        <span className="typewriter-underline">
                            <Typewriter
                                options={{
                                    strings: ['Liquidity Providers', 'Market Makers', 'Web3 Advisors'],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </span></h1>
                </LeftFadeIn>
                <LeftFadeIn delay={1}>
                    <h3>Shorewoods is a proprietary liquidity provider and market maker, focused on building liquidity and generating yield from various crypto protocols and DEX's</h3>
                </LeftFadeIn>
            </div>
        </div>
    );
}

export default HomeContainerMain;
import Topbar from "../components/topbar/Topbar";
import AboutContainer from "../components/containers/About/AboutContainer";
import Footbar from "../components/footer/Footbar";
import { useEffect, useState } from "react";
import AboutInformationContainer from "../components/containers/About/AboutInformationContainer";
import OurServicesContainer from "../components/containers/About/OurServicesContainer";
import OurTeamContainer from "../components/containers/About/OurTeamContainer";

function About() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      if (!isLoaded) {
        window.scrollTo(0,0);
        setIsLoaded(true);
      }
    }, [isLoaded]);

    return (
      <div>
        <Topbar/> 
        <AboutContainer/>
        <AboutInformationContainer/>
        <OurServicesContainer/>
        <OurTeamContainer/>
        <Footbar/>
      </div>
    );
  }
  
  export default About;
import UpFadeIn from '../animations/motion/UpFadeIn';
import TokensAndExchangesMarquee from '../subparts/TokensAndExchangesMarquee';
import './styles/genericcontainer.css'

function MarketMakingContainer() {

    return (
        <div className='generic-container'>
            <div className='info'>
                <UpFadeIn delay={0.5}>
                    <h1>Market Making</h1>
                </UpFadeIn>
                <UpFadeIn delay={0.75}>
                    <h3>Shorewoods provides market making services for protocol tokens, as well as exchanges, both decentralized and centralized</h3>
                </UpFadeIn>
            </div>
            <UpFadeIn delay={1}>
                <TokensAndExchangesMarquee />
            </UpFadeIn>
        </div>
    );
}

export default MarketMakingContainer;
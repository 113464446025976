import "./styles/tokens-and-exchanges-marquee.css"
import MarqueeScrollable from "../animations/motion/MarqueeScrollable";

function TokensAndExchangesMarquee() {
    return (
        <div className="tokens-and-exchanges-marquee">
            <h3>Shorewoods is able to work with any token, no matter their size, liquidity, or exchange listing</h3>
            <MarqueeScrollable baseVelocity={-5}>BITCOIN ETHEREUM SOLANA MATIC</MarqueeScrollable>
            <MarqueeScrollable baseVelocity={5}>BINANCE BYBIT COINBASE HTX</MarqueeScrollable>
        </div>

    );
}

export default TokensAndExchangesMarquee;
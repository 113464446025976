import { motion } from 'framer-motion';

function LeftFadeIn({ children, delay }) {
  return (
    <motion.div
      initial={{ opacity: 0, x:-100 }}
      animate={{ opacity: 1, x:0 }}
      transition={{
        duration: 0.8,
        delay: delay
      }}
    >
      {children}
    </motion.div>
  );
}

export default LeftFadeIn;

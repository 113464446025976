import CommonInformationContainer from '../CommonInformationContainer';
import ScrollFadeIn from '../../animations/motion/ScrollFadeIn';
function OurTeamContainer() {

    return (
        <CommonInformationContainer>
            <ScrollFadeIn>
                <h1>Our Team</h1>
                <span>
                    Shorewoods is led by a team of seasoned professionals with years of expertise in trading, quantitative finance, blockchain technology and cryptocurrency.
                </span>
            </ScrollFadeIn>
        </CommonInformationContainer>
    );
}

export default OurTeamContainer;
import UpFadeIn from '../animations/motion/UpFadeIn';
import './styles/genericcontainer.css'

function LiquidityProvisioningContainer() {

    return (
        <div className='generic-container'>
            <div className='info'>
                <UpFadeIn delay={0.5}>
                    <h1>Liquidity Provisioning</h1>
                </UpFadeIn>
                <UpFadeIn delay={0.75}>
                    <h3>Shorewoods is able to help protocols with their liquidity needs, especially in the earlier stages </h3>
                    <h4>We deploy capital into DeFi protocols, restaking providers, AMMs, or other onchain liquidity pools </h4>
                </UpFadeIn>
            </div>
        </div>
    );
}

export default LiquidityProvisioningContainer;
import { useEffect } from "react";

function OnBoard() {
  useEffect(() => {
    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdsgz9o1qITxqlacTYUPY5qO5OCdHc9QNPStvQpJt0lehYDVw/viewform";
  }, []);

  return (
    <div style={{
      width: "100vw",
      height: "100vh",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
    </div>
  );
}

export default OnBoard;

import CommonInformationContainer from '../CommonInformationContainer';
import ScrollFadeIn from '../../animations/motion/ScrollFadeIn';

function WhatIsMarketMaking() {

    return (
        <CommonInformationContainer>
            <ScrollFadeIn>
                <h1>What is Market Making?</h1>
                <span>
                    Market making involves providing liquidity to trading pairs on decentralized exchanges (DEXs) or centralized exchanges. This process is essential for ensuring that buyers and sellers can execute trades efficiently without significant price fluctuations. Market makers, like Shorewoods, place both buy and sell orders for a specific cryptocurrency asset, profiting from the spread between the bid and ask prices. By continuously updating these orders based on market conditions, they help stabilize the market, reduce volatility, and improve the overall trading experience for participants.
                </span>
            </ScrollFadeIn>
        </CommonInformationContainer>
    );
}

export default WhatIsMarketMaking;
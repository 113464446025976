import CommonInformationContainer from '../CommonInformationContainer';
import ScrollFadeIn from '../../animations/motion/ScrollFadeIn';

function AboutInformationContainer() {

    return (
        <CommonInformationContainer>
            <ScrollFadeIn>
                <h1>About Shorewoods</h1>
                <span>
                    Shorewoods is a proprietary liquidity provider and market maker, focused on building liquidity and generating yield from various crypto protocols founded by a group of experienced and dedicated team of early cryptocurrency adopters. Shorewoods' mission is to enhance market efficiency, improve user experience in navigating in decentralized finance and to facilitate trading.
                </span>
            </ScrollFadeIn>
        </CommonInformationContainer>
    );
}

export default AboutInformationContainer;
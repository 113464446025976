import { motion } from "framer-motion";

function ScrollFadeIn({ children, delay }) {
    const displayVariants = {
        offscreen: {
            x: -300,
            opacity: 0
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 1
            }
        }
    };

    return (
        <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={displayVariants}
            viewport={{ once: true }}
        >
            {children}
        </motion.div>
    );
}

export default ScrollFadeIn;

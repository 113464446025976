import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import leaf from '../../../assets/svg/autumn-leaf.svg'
import './styles/particles.css'

function LeafParticles() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const options = useMemo(() => ({
        fpsLimit: 60,
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
            },
            modes: {
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            tilt: {
                direction: "random",
                enable: true,
                move: true,
                value: {
                    min: 0,
                    max: 360
                },
                animation: {
                    enable: true,
                    speed: 10
                }
            },
            move: {
                direction: "bottom",
                enable: true,
                random: false,
                speed: 1,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 50,
            },
            opacity: {
                value: 0.4,
            },
            rotate: {
                value: {
                    min: 0,
                    max: 360
                }
            },
            shape: {
                type: ["image"],
                options: {
                    image: {
                        src: leaf,
                        height: 5,
                        width: 5
                    }
                }
            },
            size: {
                value: { min: 5, max: 20 },
            },
        },
        detectRetina: true,
        //fullScreen: { enable: false }
    }), []);


    if (init) {
        return (
            <Particles
                id="tsparticles"
                options={options}
                className="particles"
            />
        );
    }

    return <></>;
}

export default LeafParticles;
import UpFadeIn from '../animations/motion/UpFadeIn';
import './styles/genericcontainer.css'

function ContactContainer() {
    const handleEmailClick = () => {
        window.location.href = 'mailto:info@shorewoods.xyz';
    };

    return (
        <div className='generic-container'>
            <div className='info'>
                <UpFadeIn delay={0.5}>
                    <h1>Contact Us</h1>
                </UpFadeIn>
                <UpFadeIn delay={0.75}>
                    <h3>We are here to help with any questions or inquiries you may have.</h3>
                </UpFadeIn>
                <UpFadeIn delay={1}>
                    <button type="button" className="btn btn-primary" onClick={handleEmailClick}>Send an email to info@shorewoods.xyz</button>
                </UpFadeIn>
            </div>
        </div>
    );
}

export default ContactContainer;
import UpFadeIn from '../../animations/motion/UpFadeIn';
import '../styles/genericcontainer.css'
import { Link } from 'react-router-dom';

function AboutContainer() {

    return (
        <div className='generic-container'>
            <div className='info'>
                <UpFadeIn delay={0.5}>
                    <h1>About Us</h1>
                </UpFadeIn>
                <UpFadeIn delay={0.75}>
                    <h3>Shorewoods is a bespoke <Link to="/liquidity-provisioning" className="white-link">liquidity provider</Link> and <Link to="/market-making" className="white-link">market maker</Link> for digital assets.</h3>
                </UpFadeIn>
            </div>
        </div>
    );
}

export default AboutContainer;
import './styles/notfoundcontainer.css'

function NotFoundContainer() {
    return (
        <div className='not-found-container'>
            <h1>404 Not Found</h1>
            <span>
                <h3>Go back to&nbsp;</h3>
                <h3><a href="/">Home Page</a></h3>
            </span>
        </div>
    );
}

export default NotFoundContainer;
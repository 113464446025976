import Topbar from "../components/topbar/Topbar";
import ContactContainer from "../components/containers/ContactContainer";
import Footbar from "../components/footer/Footbar";
import { useEffect, useState } from "react";

function Contact() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      window.scrollTo(0,0);
      setIsLoaded(true);
    }
  }, [isLoaded]);

    return (
      <div>
        <Topbar/> 
        <ContactContainer/>
        <Footbar/>
      </div>
    );
  }
  
  export default Contact;
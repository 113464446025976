import './styles/common-information-container.css'

function CommonInformationContainer({children}) {

    return (
        <div className='common-information-container'>
            {children}
        </div>
    );
}

export default CommonInformationContainer;
import React from 'react';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Route, Routes, HashRouter } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contacts';
import NotFound from './pages/NotFound';
import About from './pages/About';
import LiquidityProvisioning from './pages/LiquidityProvisioning';
import MarketMaking from './pages/MarketMaking';
import LeafParticles from './components/animations/particles/LeafParticles';
import OnBoard from './pages/OnBoard';

function App() {
  return (
    <div className="App">
      <LeafParticles/>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About/>} />
          <Route path="/liquidity-provisioning" element={<LiquidityProvisioning/>} />
          <Route path="/market-making" element={<MarketMaking/>} />
          <Route path="/onboard" element={<OnBoard/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;

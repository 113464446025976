import './styles/Footbar.css'
import menuItems from '../topbar/TopbarItems.json'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Footbar() {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setItems(menuItems);
    }, []);

    const goToHomePage = () => {
        navigate('/');
        window.scrollTo(0, 0);
    };

    return (
        <div className='Footbar'>
            <div className='Footbar-menu'>
                <nav>
                    <ul>
                        {items.map((item) => (
                            <li key={item.id}>
                                <Link to={item.url}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className='Footbar-organization' onClick={goToHomePage}>
                <div className='Footbar-logo'></div>
                <h1>SHOREWOODS</h1>
            </div>
        </div>
    );
}

export default Footbar;